<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.name"
        :rules="$options.rules.name"
        label="Nom"
        type="text"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.partType"
        :rules="$options.rules.partType"
        label="Type de référence"
        :item-list="partTypeList"
        item-text="name"
        @input="change"
      />
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'RejectionCauseForm',
  components: { AgAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    partTypeList: [],
  }),
  computed: {
    isValidRejectionCause () {
      return this.mainFormValid
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidRejectionCause)
      },
    },
    isValidRejectionCause: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidRejectionCause)
      },
    },
  },
  async mounted () {
    this.partTypeList = await Repositories.partType.getList()
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidRejectionCause)
    },
  },
  rules: {
    name: [
      FormRules.required('requis'),
    ],
    partType: [
      FormRules.required('requis'),
    ],
  },
}
</script>
